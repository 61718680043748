import CryptoJS from 'crypto-js';

import { encrypt } from './common/aes';

const endpoint = process.env.VUE_APP_DDPAY_HOST;
// const endpoint = 'https://sso-dev.citycoins.cc/apps/qpoints/authorize'; // 開發環境
// const endpoint = 'https://sso-staging.citycoins.cc/apps/qpoints/authorize';
const vendorId = 'smc';
const merchantId = 'yunlin';
const platformId = 'yunlin-app';
const projectId = 'yunlinv2';
const appId = process.env.VUE_APP_DDPAY_APP_ID;
const appSecret = process.env.VUE_APP_DDPAY_APP_SECRET;
 
// https://web-dev.ddpay.ai/apis/auth-redirect?D01=staging&D02=D_1LJd7kjE4&D03=KOAZMMQG&partner- code=smc&merchant-id=yunlin>
const userToken = "";
const replyToken = "";
const shortApiId = process.env.VUE_APP_DDPAY_SHORT_API_ID;

function addQueryToUrl(url, newQueryParams) {
  // 使用 URL 對象來解析和處理 URL
  const parsedUrl = new URL(url);
  
  // 遍歷新的 query 參數並加入到當前 URL 的 searchParams 中
  for (const key in newQueryParams) {
    if (key in newQueryParams) {
      parsedUrl.searchParams.set(key, newQueryParams[key]);
    }
  }

  // 返回修改過後的完整 URL 字串
  return parsedUrl.toString();
}

const generateNextUrl = (nextUrl) => {
  const parsedUrl = new URL(nextUrl);
  const urlParams = new URLSearchParams(parsedUrl.search);
  const d01 = urlParams.get('D01') || 'staging';
  const shopId = urlParams.get('D02') || 'D_ZbveKM6Xo';
  const terminalId = urlParams.get('D03') || 'KEWRNBJX';

  return addQueryToUrl(getNextDomain(), {
    'D01': d01,
    'D02': shopId,
    'D03': terminalId,
    'partner-code': vendorId,
    'merchant-id': merchantId
  });
}

const getNextDomain = () => {
  return process.env.VUE_APP_DDPAY_URL;
}

const usePay = () => {
  const getRedirectUrl = ({
    userId = '',
    userName = '',
    userPhone = '',
    userEmail = '',
    nextUrl = '',
  } = {}) => {
    if (!userId) {
      throw new Error('userId is required');
    }
    if (!userName) {
      throw new Error('userName is required');
    }
    if (!userPhone) {
      throw new Error('userPhone is required');
    }
    if (!userEmail) {
      throw new Error('userEmail is required');
    }
    if (!nextUrl) {
      throw new Error('nextUrl is required');
    }

    const customData = {
      "project_id": projectId,
      "identity_pool_id": "",
      "identity_id": userId, // SSO模組取得的userId
      "invite_code": "",
      "user_token": "", // 保留欄位
      "user_name": userName, 
      "user_phone": userPhone,
      "user_email": userEmail,
    };
    
    const payloadStr = JSON.stringify(customData);
    const encryptedData = encrypt(payloadStr, appSecret);
    const timestamp = new Date().getTime();
    const rawData = timestamp + vendorId + platformId + appId + userId + userToken + replyToken + shortApiId;
    const computedSign = CryptoJS.HmacSHA256(rawData, appSecret).toString(CryptoJS.enc.Hex);
    
    const query = {
      'x-3rd-timestamp': timestamp,
      'x-3rd-vendor-id': vendorId,
      'x-3rd-platform-id': platformId,
      'x-3rd-app-id': appId,
      'x-3rd-user-id': userId,
      'x-3rd-custom-data': encryptedData,
      'x-3rd-token': replyToken,
      'x-3rd-frame-url': '',
      'x-3rd-signature': computedSign,
      'x-3rd-next-url': generateNextUrl(nextUrl),
    };

    const queryString = new URLSearchParams(query).toString();
    return `${endpoint}?${queryString}`;
  }

  return {
    getRedirectUrl
  }
}

export default usePay;
